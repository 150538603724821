.card{
    box-shadow: 0px 1px 8px #0345c0;
    
}
.light{
    background-color: white;
    color: black;
    transition: 0.8s;
}

.dark{
    background-color: #04222a;
    color: white;
    transition: 0.8s;
}
img.card-img-top{
    height: 200px;
}

span{
    z-index: 0;
}
span.start-100{
    left: 90%!important;
    
}
 .btn-dark, .btn-light{
     font-weight: 700;
     background-color: #dc3545;
 }