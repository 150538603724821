
.navbar-light {
  
  
    box-shadow: 0px 1px 8px #0345c0;
    display: flex;
    align-items: center;
    top: 0;
    z-index: 1;
    position: -webkit-sticky;
    position: sticky;
    background-color: white;
    transition: 0.9s;
  } 
  
  .navbar-dark {
    
  
    box-shadow: 0px 1px 8px #0345c0;
    display: flex;
    align-items: center;
    top: 0;
    z-index: 1;
    position: -webkit-sticky;
    position: sticky;
    background-color: #04222a;
    transition: 0.9s;
    width: 100%;
  }
  
  img {
    width: 220px;
    height: 60px;
  }
  .logo{
      padding-left: 20px;
  }
  
  .nav-link {
    font-weight: bold;
    font-size: 18px;
  }
  
  .toggle-btn {
    padding-left: 50px;
  }
   
  
  
  
  